"use client";

import { ArrowRight, Menu } from "lucide-react";
import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Separator } from "@/components/ui/separator";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Button } from "@/components/ui/button";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { ToggleTheme } from "@/components/layout/toggle-theme";
import { getServices } from "@/services/services";
import { IService } from "@/types/service";
import useGTMEvent from "@/hooks/useGTM";

interface RouteProps {
  href: string;
  label: string;
}

interface ServiceProps {
  title: string;
  description: string;
}

const routeList: RouteProps[] = [
  // {
  //   href: "/use-cases",
  //   label: "Use Cases",
  // },
  {
    href: "/academy",
    label: "L'Academy",
  },
  {
    href: "/blog",
    label: "Blog",
  },
  {
    href: "/announcements",
    label: "Annonces",
  },
  // {
  //   href: "/#testimonials",
  //   label: "Testimonials",
  // },
];

const serviceList: ServiceProps[] = [
  {
    title: "Logiciels sur-mesure",
    description:
      "Créez et améliorez des produits logiciels en quelques jours, avec une garantie à 100%.",
  },
  {
    title: "Conseil en Produit & UX",
    description:
      "Adoptez une approche orientée produit avec des audits et un meilleur design UX et produit.",
  },
  {
    title: "SaaS Academy",
    description:
      "Apprenez tout ce qu'il faut savoir pour concevoir, développer et déployer des produits logiciels.",
  },
];

export const Navbar = () => {
  const sendGTMEvent = useGTMEvent();
  const [isOpen, setIsOpen] = useState(false);
  const { user: currentUser } = useCurrentUser();
  const [services, setServices] = useState<IService[]>([]);

  const onGetServices = async () => {
    try {
      const servicesList = await getServices(true);
      setServices(servicesList);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    onGetServices();
  }, []);

  return (
    <header className="shadow-inner bg-opacity-15 w-[90%] md:w-[80%] lg:w-[85%] lg:max-w-screen-xl top-5 mx-auto sticky border border-secondary dark:border-border z-40 rounded-lg flex justify-between items-center p-3 bg-card">
      <Link href="/" className="font-bold text-lg flex items-center">
        <Image
          className="ml-2 mr-2 dark:invert"
          src={"/images/logo.png"}
          alt={""}
          width={90}
          height={24}
        />
      </Link>
      {/* <!-- Mobile --> */}
      <div className="flex items-center lg:hidden">
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild>
            <Menu
              onClick={() => setIsOpen(!isOpen)}
              className="cursor-pointer lg:hidden"
            />
          </SheetTrigger>

          <SheetContent
            side="left"
            className="flex flex-col justify-between rounded-tr-2xl rounded-br-2xl bg-card border-secondary"
          >
            <div>
              <SheetHeader className="mb-4 ml-4">
                <SheetTitle className="flex items-center">
                  <Link href="/" className="flex items-center">
                    <Image
                      className="mr-2 dark:invert"
                      src={"/images/logo.png"}
                      alt={""}
                      width={90}
                      height={24}
                    />
                  </Link>
                </SheetTitle>
              </SheetHeader>

              <div className="flex flex-col gap-2">
                {routeList.map(({ href, label }) => (
                  <Button
                    key={href}
                    onClick={() => setIsOpen(false)}
                    asChild
                    variant="ghost"
                    className="justify-start text-base"
                  >
                    <Link href={href}>{label}</Link>
                  </Button>
                ))}
              </div>
            </div>

            <SheetFooter className="flex-col sm:flex-col justify-start items-start gap-8">
              <div className="flex flex-col gap-6 w-full">
                {!currentUser && (
                  <>
                    <Link
                      aria-label="Aller à la page de connexion"
                      href="/login"
                      target="_blank"
                      className="font-semibold px-2 text-sm hover:text-primary"
                      onClick={() =>
                        sendGTMEvent("ci_event", {
                          category: "navigation",
                          value: "go_to_login",
                        })
                      }
                    >
                      Se connecter
                    </Link>
                    {/* <Link
                      aria-label="Prendre un rendez-vous"
                      href="https://calendar.app.google/uWahKEJtqJBQUMMJA"
                      target="_blank"
                    >
                      <Button
                        className="font-bold w-full"
                        onClick={() =>
                          sendGTMEvent("ci_event", {
                            category: "meeting",
                            value: "book_a_meeting",
                          })
                        }
                      >
                        Prendre un rendez-vous
                      </Button>
                    </Link> */}
                  </>
                )}

                {currentUser && (
                  <>
                    <Link
                      aria-label="Aller au tableau de bord"
                      href="/app/dashboard"
                    >
                      <Button
                        size="sm"
                        className="font-bold group/arrow"
                        onClick={() =>
                          sendGTMEvent("ci_event", {
                            category: "navigation",
                            value: "go_to_dashboard",
                          })
                        }
                      >
                        Aller au tableau de bord
                        <ArrowRight className="size-5 ml-2 group-hover/arrow:translate-x-1 transition-transform" />
                      </Button>
                    </Link>
                  </>
                )}
              </div>

              <Separator className="mb-2" />

              <ToggleTheme />
            </SheetFooter>
          </SheetContent>
        </Sheet>
      </div>

      {/* <!-- Desktop --> */}
      <NavigationMenu className="hidden lg:block mx-auto">
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger className="bg-card text-base">
              Services
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <div className="grid w-[360px] gap-5 p-4">
                {/* <Image
                  src="https://avatars.githubusercontent.com/u/75042455?v=4"
                  alt="RadixLogo"
                  className="h-full w-full rounded-md object-cover"
                  width={600}
                  height={600}
                /> */}
                <ul className="flex flex-col gap-2 w-full">
                  {services.map(({ title, description }) => (
                    <li
                      key={title}
                      className="rounded-md p-3 text-sm hover:bg-secondary/50"
                    >
                      <p className="mb-1 font-semibold leading-none text-foreground">
                        {title}
                      </p>
                      <p className="line-clamp-3 text-muted-foreground">
                        {description}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </NavigationMenuContent>
          </NavigationMenuItem>

          {routeList.map(({ href, label }) => (
            <NavigationMenuItem key={href}>
              <NavigationMenuLink asChild>
                <Link href={href} className="text-base px-2">
                  {label}
                </Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
          ))}
        </NavigationMenuList>
      </NavigationMenu>

      <div className="hidden lg:flex gap-3 items-center">
        {!currentUser && (
          <>
            <Link
              aria-label="Aller à la page de connexion"
              href="/login"
              target="_blank"
              className="font-semibold px-2 text-sm hover:text-primary"
              onClick={() =>
                sendGTMEvent("ci_event", {
                  category: "navigation",
                  value: "go_to_login",
                })
              }
            >
              Se connecter
            </Link>
            {/* <Link
              aria-label="Prendre un rendez-vous"
              href="https://calendar.app.google/uWahKEJtqJBQUMMJA"
              target="_blank"
            >
              <Button
                className="font-bold group/arrow"
                onClick={() =>
                  sendGTMEvent("ci_event", {
                    category: "meeting",
                    value: "book_a_meeting",
                  })
                }
              >
                Prendre un rendez-vous
              </Button>
            </Link> */}
          </>
        )}

        {currentUser && (
          <>
            <Link aria-label="Tableau de bord" href="/app/dashboard">
              <Button
                size="sm"
                className="font-bold group/arrow"
                onClick={() =>
                  sendGTMEvent("ci_event", {
                    category: "navigation",
                    value: "go_to_dashboard",
                  })
                }
              >
                Tableau de bord
                <ArrowRight className="size-5 ml-2 group-hover/arrow:translate-x-1 transition-transform" />
              </Button>
            </Link>
          </>
        )}

        <ToggleTheme />
      </div>
    </header>
  );
};
