"use client";

import { icons } from "lucide-react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Button } from "@/components/ui/button";
import useGTMEvent from "@/hooks/useGTM";

interface PromisesProps {
  icon: string;
  title: string;
  description: string;
}

const promiseList: PromisesProps[] = [
  {
    icon: "Rocket",
    title: "Réinventer le Travail",
    description:
      "Des solutions pour transformer vos méthodes et anticiper l'avenir.",
  },
  {
    icon: "Lightbulb",
    title: "Inspirer l'Innovation",
    description:
      "Des outils pour concrétiser vos idées et dépasser vos objectifs.",
  },
  {
    icon: "Users",
    title: "Unir les Talents",
    description: "Des plateformes pour connecter, apprendre et bâtir ensemble.",
  },
  {
    icon: "Infinity",
    title: "Améliorer en Continu",
    description:
      "Des produits qui simplifient vos actions et créent un impact durable.",
  },
];

export const PromisesSection = () => {
  const sendGTMEvent = useGTMEvent();

  return (
    <section id="benefits" className="container py-24 sm:py-32">
      <div className="grid lg:grid-cols-2 place-items-center gap-6 lg:gap-24">
        <div className="text-center lg:text-left">
          <h2 className="text-lg text-primary mb-2 tracking-wider">
            Nos Solutions
          </h2>

          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Des outils pour vos réussites.
          </h2>
          <p className="text-lg text-muted-foreground mb-8">
            Nous concevons une suite de solutions logicielles pensée pour les
            ambitieux(ses), entrepreneur(e)s et entreprises.
            <br />
            <br />
            Notre mission : vous aider à atteindre vos objectifs avec efficacité
            et sérénité.
          </p>

          {/* TODO add the section of our solutions */}
          {/* <div className="flex flex-col items-center md:items-start">
            <Link
              aria-label="Prendre un rendez-vous"
              href="https://calendar.app.google/uWahKEJtqJBQUMMJA"
              target="_blank"
            >
              <Button
                className="font-bold w-full"
                onClick={() =>
                  sendGTMEvent("ci_event", {
                    category: "meeting",
                    value: "book_a_meeting",
                  })
                }
              >
                Prendre un rendez-vous
              </Button>
            </Link>
          </div> */}
        </div>

        <div className="grid lg:grid-cols-2 gap-4 w-full">
          {promiseList.map(({ icon, title, description }, index) => (
            <Card
              key={title}
              className="bg-tertiary hover:bg-background transition-all delay-75 group/number"
            >
              <CardHeader>
                <div className="flex justify-between">
                  <Icon
                    name={icon as keyof typeof icons}
                    size={32}
                    color="hsl(var(--primary))"
                    className="mb-6 text-primary"
                  />
                  <span className="text-5xl text-muted-foreground/15 font-medium transition-all delay-75 group-hover/number:text-muted-foreground/30">
                    0{index + 1}
                  </span>
                </div>

                <CardTitle>{title}</CardTitle>
              </CardHeader>

              <CardContent className="text-muted-foreground text-sm">
                {description}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};
