"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { getBlogPosts } from "@/services/blog";
import { formatDate } from "@/lib/date";
import { cn } from "@/lib/utils";
import { IBlogPost } from "@/types/blog-post";
import { Button } from "@/components/ui/button";
import useGTMEvent from "@/hooks/useGTM";

interface BlogPostsProps {
  featured?: boolean;
}

export const BlogSection = ({ featured }: BlogPostsProps) => {
  const sendGTMEvent = useGTMEvent();
  const [posts, setPosts] = useState([] as IBlogPost[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBlogPosts({ published: true, ...(featured ? { featured } : {}) })
      .then((postsAPI: IBlogPost[]) => {
        setPosts(postsAPI);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <section
      className={cn(
        "container flex flex-col items-center",
        featured ? "py-24 sm:py-32" : "pb-32"
      )}
      id="testimonials"
    >
      {featured && (
        <div className="text-center mb-8">
          <h2 className="text-lg text-primary text-center mb-2 tracking-wider">
            Notre Blog
          </h2>

          <h2 className="text-3xl md:text-4xl text-center font-bold mb-4">
            Nos conseils pour développer vos logiciels
          </h2>
        </div>
      )}

      {!loading && !!posts.length && (
        <div className="max-w-[70rem] grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {posts.map(
            ({ slug, image, title, description, updatedAt, readTime }) => (
              <Card
                key={title}
                className="flex flex-col items-start justify-between h-full bg-card shadow-inner border border-secondary dark:border-none overflow-hidden "
              >
                <Link className="w-full" href={"/blog/" + slug}>
                  <CardHeader className="group flex p-0 gap-4">
                    <Image
                      src={image?.url || "/assets/placeholder.svg"}
                      alt={image?.alttext || title}
                      width={400}
                      height={200}
                      decoding="async"
                      loading="lazy"
                      className="aspect-[16/9] w-full bg-gray-100 object-cover object-left sm:aspect-[2/1] lg:aspect-[3/2] blur-0 hover:scale-105"
                    />

                    <CardTitle className="line-clamp-2 text-xl px-6 group-hover:text-primary">
                      {title}
                    </CardTitle>
                  </CardHeader>
                </Link>

                <CardContent className="text-muted-foreground pt-6">
                  <div className="pb-4">
                    <p className="line-clamp-3 text-sm leading-6">
                      {description}
                    </p>
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.75"
                        stroke="currentColor"
                        className="h-4 w-4 text-muted-foreground/60"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                        ></path>
                      </svg>
                      <time className="text-xs">{formatDate(updatedAt)}</time>
                    </div>
                    <div className="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.75"
                        stroke="currentColor"
                        className="h-4 w-4 text-muted-foreground/60"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <p className="text-xs font-normal">
                        {readTime} min de lecture
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )
          )}
        </div>
      )}

      {!loading && !posts.length && (
        <div className="flex flex-col justify-center text-muted-foreground">
          <p>Aucun article de blog disponible.</p>
        </div>
      )}

      {loading && (
        <div className="max-w-[70rem] grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(3)].map((_, index) => (
            <Card
              key={index}
              className="flex flex-col items-start justify-between h-full bg-card shadow-inner border border-secondary dark:border-none overflow-hidden "
            >
              <CardHeader className="group w-full flex p-0 gap-4">
                <Skeleton className="h-[200px] w-[400px] max-w-full" />

                <div className="px-6">
                  <Skeleton className="h-4  w-full" />
                </div>
              </CardHeader>

              <CardContent className="pt-6">
                <div className="flex flex-col gap-2 pb-4">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-32" />
                </div>

                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.75"
                      stroke="currentColor"
                      className="h-4 w-4 text-muted-foreground/60"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                      ></path>
                    </svg>
                    <Skeleton className="h-4 w-24" />
                  </div>
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.75"
                      stroke="currentColor"
                      className="h-4 w-4 text-muted-foreground/60"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <Skeleton className="h-4 w-16" />
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      )}

      {featured && (
        <Link aria-label="Voir tous les articles" href="/blog" className="py-8">
          <Button
            variant="outline"
            onClick={() =>
              sendGTMEvent("ci_event", {
                category: "navigation",
                value: "go_to_blog",
              })
            }
          >
            Voir tous les articles
          </Button>
        </Link>
      )}
    </section>
  );
};
