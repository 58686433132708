"use client";

import Image from "next/image";

import { Marquee } from "@/components/ui/marquee";

interface TrustedByProps {
  logo: string;
  name: string;
}

const trustedBy: TrustedByProps[] = [
  {
    logo: "/images/clients/societeinfo.png",
    name: "Societeinfo",
  },
  {
    logo: "/images/clients/convergo.png",
    name: "Convergo",
  },
  {
    logo: "/images/clients/saint-gobain.png",
    name: "Saint Gobain",
  },
  {
    logo: "/images/clients/covea.png",
    name: "Covea",
  },
];

export const TrustedBySection = () => {
  return (
    <section id="sponsors" className="max-w-[75%] mx-auto pb-24 sm:pb-32">
      <h2 className="text-lg md:text-xl text-center mb-8 font-bold">
        Ils nous font confiance
      </h2>

      <div className="mx-auto">
        <Marquee
          className="gap-[3rem]"
          fade
          numberOfCopies={6}
          innerClassName="gap-[3rem]"
        >
          {trustedBy.map(({ logo, name }) => (
            <div
              key={name}
              className="flex items-center text-xl md:text-2xl font-medium"
            >
              <Image
                src={logo}
                alt={name}
                width={120}
                height={56}
                className="grayscale hover:grayscale-0 transition-all duration-300"
                style={{
                  maxWidth: "120px",
                  maxHeight: "56px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
};
