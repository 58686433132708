"use client";

import Link from "next/link";
import { ArrowRight, GraduationCap, RocketIcon } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import useGTMEvent from "@/hooks/useGTM";
import { useCurrentUser } from "@/hooks/useCurrentUser";

export const HeroSection = () => {
  const { user: currentUser } = useCurrentUser();
  const sendGTMEvent = useGTMEvent();

  const headerInfo = {
    title: (
      <>
        Le &nbsp;
        <span className="text-transparent celerinced">product studio</span>
        &nbsp; qui forme et transforme.
      </>
    ),
    desc: "Un accompagnement personnalisé pour vous former et concevoir vos solutions en software, data et IA.",
  };

  return (
    <section className="container w-full">
      <div className="grid place-items-center lg:max-w-screen-xl gap-8 mx-auto py-20 md:py-32">
        <div className="text-center space-y-8">
          <Badge
            variant="outline"
            className="text-sm py-2 bg-card shadow-inner border border-secondary"
          >
            <span className="mr-2 text-primary">
              <Badge>Nouvelle</Badge>
            </span>
            <span className="px-2"> L'Academy, formations sur mesure </span>
          </Badge>

          <div className="max-w-[60rem] mx-auto text-center text-4xl md:text-6xl font-bold">
            <h1 className="flex flex-wrap justify-center">
              {headerInfo.title}
            </h1>
          </div>

          <p className="max-w-screen-sm mx-auto text-xl text-muted-foreground">
            {headerInfo.desc}
          </p>

          <div className="space-y-4 md:space-y-0 md:space-x-4">
            <Link
              aria-label="Voir les formations"
              target="_blank"
              href="/academy"
            >
              <Button
                size="lg"
                className="font-bold group/arrow"
                onClick={() =>
                  sendGTMEvent("ci_event", {
                    category: "navigation",
                    value: "go_to_trainings",
                  })
                }
              >
                Voir nos formations
                <GraduationCap className="size-5 ml-2 group-hover/arrow:translate-x-1 transition-transform" />
              </Button>
            </Link>

            {/* {!currentUser && (
              <Link
                aria-label="Lancer mon projet"
                href="https://calendar.app.google/uWahKEJtqJBQUMMJA"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  size="lg"
                  variant="outline"
                  className="w-5/6 md:w-1/4 font-bold group/arrow"
                  onClick={() =>
                    sendGTMEvent("ci_event", {
                      category: "auth",
                      value: "launch_project",
                    })
                  }
                >
                  Lancer mon projet
                  <RocketIcon className="size-5 ml-2 group-hover/arrow:translate-x-1 transition-transform" />
                </Button>
              </Link>
            )} */}

            {currentUser && (
              <Link
                aria-label="Aller au tableau de bord"
                target="_blank"
                href="/app/dashboard"
              >
                <Button
                  size="lg"
                  variant="outline"
                  className="font-bold group/arrow"
                  onClick={() =>
                    sendGTMEvent("ci_event", {
                      category: "navigation",
                      value: "go_to_dashboard",
                    })
                  }
                >
                  Aller au tableau de bord
                  <ArrowRight className="size-5 ml-2 group-hover/arrow:translate-x-1 transition-transform" />
                </Button>
              </Link>
            )}
          </div>
        </div>

        {/* <div className="relative group mt-14">
          <div className="absolute top-2 lg:-top-8 left-1/2 transform -translate-x-1/2 w-[90%] mx-auto h-24 lg:h-80 bg-primary/50 rounded-full blur-3xl"></div>
          <Image
            width={1200}
            height={1200}
            className="w-full md:w-[1200px] mx-auto rounded-lg relative rouded-lg leading-none flex items-center border border-t-2 border-secondary  border-t-primary/30"
            src={
              theme === "light"
                ? "/hero-image-light.jpeg"
                : "/hero-image-dark.jpeg"
            }
            alt="dashboard"
          />

          <div className="absolute bottom-0 left-0 w-full h-20 md:h-28 bg-gradient-to-b from-background/0 via-background/50 to-background rounded-lg"></div>
        </div> */}
      </div>
    </section>
  );
};
