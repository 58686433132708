"use client";

import Link from "next/link";
import Image from "next/image";

import { Icons } from "@/components/icons";
import useGTMEvent from "@/hooks/useGTM";
// import { importIntegrationsNoSSR } from "@/lib/integratiPons";

import { ToggleTheme } from "../toggle-theme";

export const FooterSection = () => {
  const sendGTMEvent = useGTMEvent();

  // const isProduction = process.env.NODE_ENV === "production";
  // const { WebsiteCarbonBadge } = importIntegrationsNoSSR();

  return (
    <footer
      id="footer"
      className="relative bg-card border-t border-secondary dark:border-border"
    >
      <div className="mx-auto max-w-6xl px-4 md:px-8 pb-8 pt-16 sm:pt-24 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-20">
          <div className="space-y-8">
            <Link href="/" className="flex font-bold items-center">
              <Image
                className="mr-2 dark:invert"
                src={"/images/logo.png"}
                alt={""}
                width={100}
                height={30}
              />
            </Link>

            <p className="text-sm leading-6 text-gray-600 dark:text-gray-400">
              Des solutions pour vous accompagner, vous former et vous
              transformer.
            </p>

            <ToggleTheme />
          </div>
          <div className="mt-16 grid grid-cols-1 gap-14 sm:gap-8 md:grid-cols-2 xl:col-span-2 xl:mt-0">
            <div className="grid grid-cols-2 gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50">
                  Solutions
                </h3>
                <ul
                  role="list"
                  className="mt-6 space-y-4"
                  aria-label="Liens des solutions"
                >
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      rel="noopener"
                      href="https://www.querytower.com"
                    >
                      <span>Querytower</span>
                    </a>
                  </li>

                  {/* <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      rel="noopener"
                      href="https://www.softcoster.com"
                    >
                      <span>SoftCoster</span>
                    </a>
                  </li> */}
                  {/* <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      // href="/solutions/retorical"
                      href="#"
                    >
                      <span>Retorical</span>
                    </a>
                  </li> */}
                  {/* <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      // href="/solutions/webouge"
                      href="#"
                    >
                      <span>Webouge</span>
                    </a>
                  </li> */}
                  {/* <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      // href="/solutions/leadmailer"
                      href="#"
                    >
                      <span>LeadMailer</span>
                    </a>
                  </li> */}
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      href="/announcements"
                    >
                      <span>Annonces</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50">
                  L&apos;Academy
                </h3>
                <ul
                  role="list"
                  className="mt-6 space-y-4"
                  aria-label="Liens des services"
                >
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      href="/academy/maitriser-ui-design"
                    >
                      <span>Maîtriser l&apos;UI Design</span>
                      <div className="ml-0.5 aspect-square size-3 rounded-full bg-gray-100 p-px dark:bg-gray-500/20">
                        <Icons.openLink />
                      </div>
                    </a>
                  </li>
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      href="/academy/maitriser-figma-pour-designers"
                    >
                      <span>Maîtriser Figma pour designers</span>
                      <div className="ml-0.5 aspect-square size-3 rounded-full bg-gray-100 p-px dark:bg-gray-500/20">
                        <Icons.openLink />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50">
                  Ressources
                </h3>
                <ul
                  role="list"
                  className="mt-6 space-y-4"
                  aria-label="Liens des ressources"
                >
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      href="/blog"
                    >
                      <span>Blog</span>
                    </a>
                  </li>
                  {/* <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/celerinc"
                    >
                      <span>GitHub</span>
                      <div className="ml-0.5 aspect-square size-3 rounded-full bg-gray-100 p-px dark:bg-gray-500/20">
                        <Icons.openLink />
                      </div>
                    </a>
                  </li> */}
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/celerinc"
                    >
                      <span>YouTube</span>
                      <div className="ml-0.5 aspect-square size-3 rounded-full bg-gray-100 p-px dark:bg-gray-500/20">
                        <Icons.openLink />
                      </div>
                    </a>
                  </li>

                  <li
                    className="w-fit"
                    onClick={() =>
                      sendGTMEvent("ci_event", {
                        category: "auth",
                        value: "launch_project",
                      })
                    }
                  >
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      href="https://calendar.app.google/uWahKEJtqJBQUMMJA"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span>Nous contacter</span>

                      <div className="ml-0.5 aspect-square size-3 rounded-full bg-gray-100 p-px dark:bg-gray-500/20">
                        <Icons.openLink />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50">
                  Légal
                </h3>
                <ul
                  role="list"
                  className="mt-6 space-y-4"
                  aria-label="Liens légaux"
                >
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      href="/privacy"
                    >
                      <span>Confidentialité</span>
                    </a>
                  </li>
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      href="/terms-conditions"
                    >
                      <span>Conditions générales</span>
                    </a>
                  </li>
                  <li className="w-fit">
                    <a
                      className="flex rounded-md text-sm text-gray-500 transition hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                      target="_blank"
                      href="/legal-notices"
                    >
                      <span>Mentions légales</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 flex flex-col items-center md:items-start justify-between gap-4 border-t border-gray-200 pt-8 sm:mt-20 sm:flex-row lg:mt-24 dark:border-gray-800">
          <p className="text-sm leading-5 text-gray-500 dark:text-gray-400 py-1">
            © 2025 Celerinc, tous droits réservés.
          </p>

          {/* {isProduction && <WebsiteCarbonBadge darkMode={false} />} */}

          {/* <div className="rounded-full border border-gray-200 py-1 pl-1 pr-2 dark:border-gray-800">
            <div className="flex items-center gap-1.5">
              <div className="relative size-4 shrink-0">
                <div className="absolute inset-[1px] rounded-full bg-emerald-500/20 dark:bg-emerald-600/20"></div>
                <div className="absolute inset-1 rounded-full bg-emerald-600 dark:bg-emerald-500"></div>
              </div>
              <span className="text-xs text-gray-700 dark:text-gray-50">
                All systems operational
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};
